
$min_viewport_width: 320px;
$max_viewport_width: 1320px;


$REGULAR: 400;
$BOLD: 700;


$WHITE: #FFFFFF;
$BLACK: #333333;
$DARK_GRAY: #6F7174;
$GRAY: #CCCCCC;
$LIGHT_GRAY: #F9F9F9;
$DARK_BLUE: #002D38;
$RED: #F50023;
$NOTE: #FF0000;
$LIGHT_BLUE: #2FC4F4;
$BUTTON_SHADOW: 0px 5px 30px #02C4F333;


@use "sass:math";

@import "../variables";
$font: (
        12: (
                font-size: 12px,
                min-font-size: 10px
        ),
        16: (
                font-size: 16px,
                min-font-size: 14px
        ),
        18: (
                font-size: 18px,
                min-font-size: 16px
        ),
        20: (
                font-size: 20px,
                min-font-size: 18px
        ),
        22: (
                font-size: 22px,
                min-font-size: 20px
        ),
        24: (
                font-size: 24px,
                min-font-size: 20px
        ),
        26: (
                font-size: 26px,
                min-font-size: 22px
        ),
        28: (
                font-size: 28px,
                min-font-size: 24px
        ),
        32: (
                font-size: 32px,
                min-font-size: 28px
        ),
        36: (
                font-size: 36px,
                min-font-size: 30px
        ),
        38: (
                font-size: 38px,
                min-font-size: 32px
        ),
        40: (
                font-size: 40px,
                min-font-size: 34px
        )
);


@function strip-unit($value) {
  @return math.div($value, $value * 0 + 1);
}

@mixin fluid-type($min-font-size, $max-font-size) {
  $u1: unit($min_viewport_width);
  $u2: unit($max_viewport_width);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      line-height: 1.4;
      @media screen and (min-width: $min_viewport_width) {
        $fs: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min_viewport_width}) / #{strip-unit($max_viewport_width - $min_viewport_width)}));
        font-size: $fs;
        line-height: 1.4;
      }
      @media screen and (min-width: $max_viewport_width) {
        font-size: $max-font-size;
        line-height: 1.4;
      }
    }
  }
}


@mixin font-modifiers() {
  @each $font-group, $ruleset in $font{
    &-#{$font-group} {
      font-size: map-get($ruleset, font-size);
      line-height: map-get($ruleset, line-height);
    }
    &-f#{$font-group} {
      @include fluid-type(map-get($ruleset, min-font-size), map-get($ruleset, font-size))
    }
  }
}


.fs {
  @include font-modifiers;
}

$sm : 576px;
$md : 767px;
$lg : 991px;
$xl : 1199px;

$margin-padding: (
        10 : 10px,
        12 : 12px,
        14 : 14px,
        16 : 16px,
        18 : 18px,
        20 : 20px,
        22 : 22px,
        24 : 24px,
        26 : 26px,
        28 : 28px,
        30 : 30px,
        32 : 32px,
        34 : 34px,
        40 : 40px,
        50 : 50px,
        60 : 60px,
        70 : 70px,
        80 : 80px,
        90 : 90px,
        100 : 100px,
        110 : 110px,
        120 : 120px,
        130 : 130px,
        140 : 140px,
);

@mixin spacing-modifiers($property) {
  @each $name, $hex in $margin-padding {
    &-#{""+$name} {
      #{$property}: $hex;
    }
    &-sm-#{""+$name} {
      @media screen and (min-width: $sm) {
        #{$property}: $hex;
      }
    }
    &-md-#{""+$name} {
      @media screen and (min-width: $md) {
        #{$property}: $hex;
      }
    }
    &-lg-#{""+$name} {
      @media screen and (min-width: $lg) {
        #{$property}: $hex;
      }
    }
    &-xl-#{""+$name} {
      @media screen and (min-width: $xl) {
        #{$property}: $hex;
      }
    }
  }
}

.m {
  @include spacing-modifiers('margin')
}
.mt {
  @include spacing-modifiers('margin-top')
}
.ml {
  @include spacing-modifiers('margin-left')
}
.mr {
  @include spacing-modifiers('margin-right')
}
.mb {
  @include spacing-modifiers('margin-bottom')
}


.p {
  @include spacing-modifiers('padding')
}
.pt {
  @include spacing-modifiers('padding-top')
}
.pl {
  @include spacing-modifiers('padding-left')
}
.pr {
  @include spacing-modifiers('padding-right')
}
.pb {
  @include spacing-modifiers('padding-bottom')
}

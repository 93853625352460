$border-radius: (
        4 : 4px,
        6 : 6px,
        8 : 8px,
        12 : 12px,
        16 : 16px,
        20 : 20px,
        24 : 24px,
);

@mixin border-radius-modifier() {
  @each $name, $pixels in $border-radius {
    &-#{""+$name} {
      border-radius: $pixels;
    }
  }
}

.br {
  @include border-radius-modifier()
}

@import "variables";

$btn-border-radius: 8px;
$btn-font-weight: $BOLD;
$btn-font-size: 16px;
$btn-transition: 0.4s;

@import "~bootstrap/scss/bootstrap";
@import "utility/font";
@import "utility/color";
@import "utility/margins-paddings";
@import "utility/border-radius";
@import "./utility/forms";
@import "mapbox";


.btn {
  min-height: 44px!important;
  width: 100% !important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
  text-transform: uppercase;

  // BG, BORDER, COLOR --- HOVER, BG, BORDER, COLOR
  &.btn-primary {
    @include button-variant($LIGHT_BLUE, $LIGHT_BLUE, $WHITE, $LIGHT_BLUE, $LIGHT_BLUE, $WHITE);
    box-shadow: $BUTTON_SHADOW;
    &:hover {
      scale: 0.95;
    }
    &:hover, &:focus, &:active {
      background: $LIGHT_BLUE;
      color: $WHITE;
      box-shadow: none!important;
    }
    &:active {
      scale: 0.90;
    }
  }
}

.form-floating {
  position: relative;
  .optional {
    position: absolute;
    right: 10px;
    top: 5px;
    font-size: 12px;
    color: $GRAY;
    transition: 0.1s;
  }
  label {
    padding-left: 15px!important;
    color: $GRAY;
    font-size: 14px;
  }
  input[type="text"], select, textarea, input[type="tel"], input[type="email"], input[type="password"] {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    height: 50px;
    border: 1px solid $GRAY;
    outline: none!important;
    box-shadow: none!important;
    border-radius: 8px!important;
    &:placeholder-shown {
      ~.optional {
        top: 18px;
      }
    }
    &:focus {
      ~.optional {
        top: 5px;
      }
    }
  }

  textarea {
    min-height: 100px;
  }

  select {
    background: transparent!important;
    position: relative;
    z-index: 10;
    option {
      background: $WHITE;
      color: $BLACK;
    }
    ~ label {
      background: transparent;
      ~ svg {
        position: absolute;
        right: 25px;
        z-index: 0;
        top: 20px;
        margin: auto;
        color: $BLACK!important;
      }
    }
  }
}

input[type="radio"], input[type="checkbox"] {
  display: none;
  & ~ label {
    display: inline-flex;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    &::before {
      height: 20px;
      width: 20px;
      position: absolute;
      border: 1px solid red;
      content: "";
      left: 0;
      top: 0;
    }
    &::after {
      position: absolute;
      height: 14px;
      width: 14px;
      left: 3px;
      top: 3px;
      content: "";
      background: red;
      visibility: hidden;
    }
  }
  &:checked ~ label::after {
    visibility: visible;
  }
}

form {
  .small-text {
    font-size: 12px;
    margin-top: 15px;
    a {
      color: inherit!important;
      text-decoration: underline;
    }
  }
  .show-pw {
    cursor: pointer;
  }
}

input[type=submit] {
  white-space: break-spaces;
}
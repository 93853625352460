$colors: (
        white: $WHITE,
        black: $BLACK,
        red: $RED,
);

@mixin color-modifiers($property: 'color') {

  @each $name, $hex in $colors {

    &-#{""+$name} {
      #{$property}: $hex;
    }
  }
}

@mixin color-hover-modifiers($property: 'color') {
  @each $name, $hex in $colors {
    &-#{""+$name}-on-hover {
      &:hover, &:focus, &:active {
        #{$property}: $hex;
      }
    }
  }
}


.color {
  @include color-modifiers();
  @include color-hover-modifiers();
}
.bg {
  @include color-modifiers(background-color);
  @include color-hover-modifiers(background-color);
}
.border-color {
  @include color-modifiers(border-color);
  @include color-hover-modifiers(border-color);
}

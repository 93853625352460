@import "variables";
@import "utility/font";

h1 {
  @include fluid-type(32px, 40px);
  line-height: 1.2!important;
  font-weight: $BOLD;
  color: $BLACK;
}

h2 {
  @include fluid-type(24px, 28px);
  line-height: 1.2!important;
  font-weight: $BOLD;
  color: $BLACK;
}

h3 {
  @include fluid-type(20px, 24px);
  line-height: 1.2!important;
  font-weight: $BOLD;
  color: $RED;
}


p {
  margin: 0;
  padding: 0;
  color: $DARK_GRAY;
  font-size: 20px;
  font-weight: $REGULAR;
}

.app-container {
  margin-bottom: 36px;
}

.chat-button {
  display: inline-flex;
  align-items: center;
  color: $RED!important;
  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: $DARK_BLUE;
    border-radius: 100%;
    margin-right: 16px;
  }
  span {
    font-size: 24px;
    font-weight: $REGULAR;
    color: $RED;
  }
}

.header-container {
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 80px;
  .logo-container {
    img {
      height: auto;
      object-fit: contain;
      max-width: 150px;
    }
  }
  a.go-back-link {
    font-size: 12px;
    color: $RED;
    text-decoration: none;
  }
}

.note {
  background: $LIGHT_GRAY;
  border-radius: 8px;
  padding: 18px 12px;
  display: flex;
  align-items: center;
  color: $NOTE;
  font-weight: $REGULAR;
  @include fluid-type(18px, 20px);
  svg {
    margin-right: 12px;
  }
}

.order-timeline {
  display: flex;
  text-align: center;
  .order-timeline-item {
    position: relative;
    flex: 1;
    &::after {
      position: absolute;
      top: 16px;
      height: 1px;
      width: 50%;
      transform: translateX(50%);
      content: "";
      background: $GRAY;
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
    .badge {
      margin: auto;
      border-radius: 100%;
      background: $DARK_GRAY;
      width: 32px;
      height: 32px;
      color: $WHITE;
      font-weight: $BOLD;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    .desc {
      margin-top: 8px;
      text-align: center;
      font-size: 16px;
      line-height: 24px;
      font-weight: $BOLD;
      @media screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 22px;
      }
      @media screen and (max-width: 576px) {
        font-size: 12px;
        line-height: 20px;
      }
    }
    &.active {
      color: $RED;
      .badge {
        background: $RED;
      }
    }
    &.done {
      color: $BLACK;
      .badge {
        background: $BLACK;
      }
      &::after {
        background: $BLACK;
      }
    }
  }
}

.map {
  border-radius: 8px;
  margin-top: 16px;
  width: 100%;
  height: 400px;
  background: $GRAY;
}


.container-404 {
  height: 100vh;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.order-summary-skeleton {
  border-radius: 8px;
  min-height: 500px;
  width: 100%;
}

.rating-disabled-note {
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}